import React, {Component} from "react";
import { Box, Typography, Container, Grid, Hidden, List, ListItem, ListItemIcon, ListItemText, Button } from "@material-ui/core";
import {obligation, commitments, achiveCommitments} from "../data/about";
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { SocialSecurity, HealthInsurance, PayRaise, PerksBonuses, PaidVacation, AchivementAward, EmployeesAlowences, MealAndBreaks } from "../component/icons";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FoundersDialog from "../component/dialog/dialog";

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.outerWidth,
            height: window.outerHeight,
            open : false,
            dialogContent: {}
        };
    }
    componentDidMount = () =>  window.addEventListener("resize", this.updateDimensions);
    updateDimensions = () =>{
        this.setState({
            width: window.outerWidth,
            height: window.outerHeight
        });
    }
    handleClose = () => {
        this.setState({open: false});
    }
    render() {
        return(
            <Box width="100%" bgcolor="var(--white)">
                <Banner This={this} />
                <History This={this} />
                <Founders This={this} />
                <ShePolicy This={this} />
                <Obligation This={this} />
                <Training This={this} />
                <HrSolutions This={this} />
            </Box>
        )
    }
}

export default AboutUs;

const Banner = (props) => {
    const {This} = props;
    return(
        <Box position="relative" className="about-banner about-bg-1 pb80" minHeight={700} width="100%" height="100vh" bgcolor="var(--secondary)">
            <Container maxWidth="lg" style={{height: "100%"}}>
                <Grid container style={{height: "100%"}}>
                    <Grid className="pt80 mb30" container alignItems="center" item sm={12} md={6} style={{color: "var(--white)"}}>
                        <Grid item>
                            <Typography className="f_w-900 mb30" variant="h2" component="h1">ABOUT US</Typography>
                            <Typography variant="subtitle1" component="p">GSL is one of the leading and reputed Manpower & Job Outsourcing Company with vast experience since 1983 , which caters to some of the leading National & International Companies to facilitate them to cope with changing times as per respective Industry needs.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Hidden smDown>
                            <Box width="100%" top="50%" position="absolute" right={0} width={(This.state.width / 2) - 100} style={{transform: "translateY(-50%)"}}>
                                <img alt="about profile" src="/img/about1.png" style={{width: "100%"}} />
                            </Box>
                        </Hidden>
                        <Hidden mdUp>
                            <Box width="100%" maxHeight="400px" height="100%">
                                <img alt="about profile mobile" src="/img/about1.png" style={{width: "100%", height:"100%", objectFit: "cover"}} />
                            </Box>
                        </Hidden>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

const History = (props) => {
    return(
        <Box className="pt100">
            <Box className="pt60">
                <Container maxWidth="lg">
                    <Typography align="center" variant="h6" color="primary">HISTORY</Typography>
                    <Typography align="center" variant="h3" className="f_w-600 mb20" style={{color: "var(--thirdColor)"}}>Our Company</Typography>
                    <Typography align="center" variant="body1" className="f_w-500" style={{color: "var(--thirdColor)", maxWidth: 700, margin: "0 auto"}}>Since 1983, GSL has been providing services to many of the reputed National and International Companies. We have been the market leaders and continue to provide according to the changing time and industry needs.</Typography>
                </Container>
            </Box>
        </Box>
    )
}

const Founders = (props) => {
    const {This} = props;
    return(
        <Box className="pt100">
            <Box className="pt60">
                <Container maxWidth="lg">
                    <Typography align="center" variant="h3" className="f_w-600 mb20" style={{color: "var(--thirdColor)"}}>Leadership</Typography>
                    <Box maxWidth={900} margin="0 auto" className="mt50">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Box className="p40" bgcolor="var(--disabled)">
                                    <Grid container>
                                        <Box textAlign="center" width="100%" className="mb30">
                                            <img src="/img/ceo.jpg" alt="Gs Logistics Pvt. Ltd. Ceo" style={{width: "280px", height: "280px", objectFit: "cover", borderRadius: "400px 400px 400px 0"}} />
                                        </Box>
                                        <Typography display="block" variant="h5" className="f_w-500 g-full">Gulab Singh Rana</Typography>
                                        <Typography display="block" variant="caption" className="f_w-500 g-full" style={{color: "var(--thirdColor)"}}>Managing Director, GSL</Typography>
                                        <Typography display="block" variant="body1" className="f_w-400 g-full mt15">Mr. Gulab Singh Rana hails from Gurugram, and is one of the pioneers In the business of Contractual manpower and the warehousing and logistics Industry. With his vast experience of more than 40 years, he has seen and studied the industry inside and out.</Typography>
                                        <Button color="primary" variant="outlined" onClick={() => This.setState({open: true, dialogContent: dialogContent.md})} className="mt25">Read More</Button>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box className="p40">
                                    <Grid container>
                                        <Box textAlign="center" width="100%" className="mb30">
                                            <img src="/img/md.jpg" alt="Gs Logistics Pvt. Ltd. Ceo" style={{width: "280px", height: "280px", objectFit: "cover", borderRadius: "400px 400px 400px 0"}} />
                                        </Box>
                                        <Typography display="block" variant="h5" className="f_w-500 g-full">Gaurav Singh Rana</Typography>
                                        <Typography display="block" variant="caption" className="f_w-500 g-full" style={{color: "var(--thirdColor)"}}>Director, GSL</Typography>
                                        <Typography display="block" variant="body1" className="f_w-400 g-full mt15">Mr. Gaurav Singh Rana hails from Gurugram. He has completed his Masters in Management and Finance from the University of Sussex, United Kingdom. He is the eldest amongst 3 siblings and has always been fascinated by the business world.</Typography>
                                        <Button color="primary" variant="outlined" onClick={() => This.setState({open: true, dialogContent: dialogContent.od})} className="mt25">Read More</Button>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <FoundersDialog This={This} />
        </Box>
    )
}

const Obligation = (props) => {
    return(
        <Box className="pt100">
            <Container maxWidth="lg" className="pt100">
                <Typography align="center" variant="h3" className="f_w-600 mb20" style={{color: "var(--thirdColor)"}}>Our Statutory Obligations</Typography>
                <Typography align="center" variant="body1" className="f_w-500" style={{color: "var(--thirdColor)", maxWidth: 700, margin: "0 auto"}}>GSL is registered under the following Statutory Norms and complying as per prescribed schedules year on year.</Typography>
                <Grid container className="pt70" spacing={3}>
                    {obligation.map(x => (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box display="flex" alignItems="center" position="relative" width="100%" minHeight={80} bgcolor="var(--disabled)">
                                <Box position="absolute" left={0} top={0} height="100%" width="3px" bgcolor="var(--thirdColor)" />
                                <Typography variant="body2" className="f_w-500 p20" style={{color: "var(--thirdColor)"}}>{x}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}


const Training = (props) => {
    const training = ["Quizzes", "Group Discussions", "Case Studies", "Role Plays", "Birthday Celebrations", "Retirement Benefits", "Gratuity Paid", "Audiovisual Training"];
    return(
        <Box className="pt100">
            <Container maxWidth="lg" className="pt100">
                <Grid container alignItems="center">
                    <Grid item sm={12} md={6}>
                        <Box>
                            <Typography variant="h6" color="primary">TRAINING</Typography>
                            <Typography variant="h3" className="f_w-600 mt20 mb20" style={{color: "var(--thirdColor)"}}>We Impart Training</Typography>
                            <Hidden mdUp>
                                <Box width="100%" className="mb20">
                                    <img src="/img/training.png" alt="hr solutions" style={{width: "100%"}} />
                                </Box>
                            </Hidden>
                            <Typography variant="body1" className="f_w-500" style={{color: "var(--thirdColor)", maxWidth: 700, margin: "0 auto"}}>GSL believes that an effective training equips employees to do their jobs better . We constantly focus on innovative methods for each training sessions which may help employees to learn and retain information.</Typography>
                        </Box>
                        <Box className="mt100">
                            <Typography variant="h3" className="f_w-600 mt20 mb20" style={{color: "var(--thirdColor)"}}>Our Training Methodology</Typography>
                            <Typography variant="body1" className="f_w-500" style={{color: "var(--thirdColor)", maxWidth: 700, margin: "0 auto"}}>We use many ways to break up the training sessions and keep trainees Attentive and Involved, through :- </Typography>
                            <Grid container spacing={3} className="mt20">
                                {training.map(x => (
                                    <Grid item xs={6} sm={6} md={4} lg={3}>
                                        <Box display="flex" alignItems="center" position="relative" width="100%" minHeight={80} bgcolor="var(--disabled)">
                                            <Box position="absolute" left={0} top={0} height="100%" width="3px" bgcolor="var(--thirdColor)" />
                                            <Typography variant="body2" className="f_w-500 p20" style={{color: "var(--thirdColor)"}}>{x}</Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Grid>
                    <Hidden smDown>
                        <Grid container item sm={12} md={6} className="pl60" spacing={2}>
                                <Grid item xs={6}>
                                    <Box width="100%">
                                        <img src="/img/tr1.jpeg" alt="hr solutions" style={{width: "100%"}} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box width="100%">
                                        <img src="/img/tr2.jpeg" alt="hr solutions" style={{width: "100%"}} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box width="100%">
                                        <img src="/img/tr6.jpeg" alt="hr solutions" style={{width: "100%"}} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box width="100%">
                                        <img src="/img/tr4.jpeg" alt="hr solutions" style={{width: "100%"}} />
                                    </Box>
                                </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </Box>
    )
}

const HrSolutions = (props) => {
    const hrSolutions = [
        {
            title: `Social\nSecurity`,
            icon: <SocialSecurity fontSize="large" />
        },
        {
            title: `Perks &\nBonuses`,
            icon: <PerksBonuses fontSize="large" />
        },
        {
            title: `Paid\nVacation`,
            icon: <PaidVacation fontSize="large" />
        },
        {
            title: `Health\nInsurance`,
            icon: <HealthInsurance fontSize="large" />
        },
        {
            title: `Achevement\nAward`,
            icon: <AchivementAward fontSize="large" />
        },
        {
            title: `Employees\nAlowances`,
            icon: <EmployeesAlowences fontSize="large" />
        },
        {
            title: `Pay\nRaise`,
            icon: <PayRaise fontSize="large" />
        },
        {
            title: `Meal\nBreaks`,
            icon: <MealAndBreaks fontSize="large" />
        }
    ]
    return(
        <Box className="pt100">
            <Container maxWidth="lg" className="pt100">
                <Box maxWidth="600px" margin="0 auto" className="mb80">
                    <Typography align="center" variant="h3" className="f_w-600 mb20" style={{color: "var(--thirdColor)"}}>Our Human Relationship & Staff Welfare</Typography>
                </Box>
                <Grid container spacing={3}>
                    {hrSolutions.map((x, i) => {
                        return(
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Box className="p60" textAlign="center" bgcolor={(i % 2) === 0 ? "var(--primary)" : "var(--secondary)"} color="var(--white)">
                                    {x.icon}
                                    <Typography variant="h6" className="f_w-400 mt10" style={{whiteSpace: 'pre-line'}}>{x.title}</Typography>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </Box>
    )
}

const ShePolicy = (props) => {
    return(
        <Box className="pt100">
            <Container maxWidth="lg" className="pt60">
                <Typography align="center" variant="h6" color="primary">Safety, Health and Environmental Policy</Typography>
                <Typography align="center" variant="h3" className="f_w-600 mb20" style={{color: "var(--thirdColor)"}}>SHE Policy</Typography>
                <Typography align="center" variant="body1" className="f_w-500" style={{color: "var(--thirdColor)", maxWidth: 1100, margin: "0 auto"}}>The management of G.S Logistics Pvt Ltd strives for excellence in all we do. We recognise the impact that our activities have on people and the environment. G.S Logistics Pvt Ltd, company Dealing in Contract Manufacturing/Job Work Outsourcing, Contractual Manpower, Recruitment Service, Warehousing and Logistics operations do hereby ensures, to implement and maintain, as far as is reasonably practicable, the activities of the company in such a manner, as to prevent harm or damage to persons and property respectfully. Safety, health and protection of the environment will form an integral part of our planning and decision making. We will manage our company, wherever we do business, in an ethical way that strikes an appropriate and well reasoned balance between economic, social and environmental needs.</Typography>
                <Box className="pt60">
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography className="f_w-500" variant="h5" style={{color: "var(--thirdColor)"}}>Our Commitments:</Typography>
                            <List>
                                {commitments.map((m, i) => (
                                    <ListItem style={{paddingLeft: 0}} className="pt5" alignItems="flex-start">
                                        <ListItemIcon className="pr5" style={{minWidth: "initial"}}>
                                            <ArrowForwardIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{variant: "body2"}} primary={m} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography className="f_w-500" variant="h5" style={{color: "var(--thirdColor)"}}>We will achieve our Commitments by:</Typography>
                            <List>
                                {achiveCommitments.map((m, i) => (
                                    <ListItem style={{paddingLeft: 0}} className="pt5" alignItems="flex-start">
                                        <ListItemIcon className="pr5" style={{minWidth: "initial"}}>
                                            <ArrowForwardIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{variant: "body2"}} primary={m} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

const dialogContent = {
    md : {
        name: "Gulab Singh Rana",
        img: "/img/ceo.jpg",
        position: "Managing Director, GSL",
        desc: `Mr. Gulab Singh Rana hails from Gurugram, and is one of the pioneers In the business of Contractual manpower and the warehousing and logistics Industry. With his vast experience of more than 40 years, he has seen and studied the industry inside and out.
        Mr. Gulab Singh Rana’s business empire started with only 10 people on his roll which has now grown to over 8000 people. With the contractual manpower business in its early stage, Mr Rana’s vision of creating a extensive and sustainable business model by giving the best of services gained momentum and he started to dominate the existing market space.
        In the Year 1998-99 he started with the business of ready-mix concrete when the business was relatively unknown in India. Today he is known and attributed as the “Godfather” in the business of Ready-mix Concrete.
        Mr. Rana has always had the foresightedness to predict the market. After conquering the business of Ready-mix concrete, he started another venture in the field of Desilting and Rehabilitation of underground pipelines by the use of automated machinery. Today he is amongst the top most players in India in the field.
        Throughout his professional career, Mr Rana has remained true to his work which has blessed him with immense success in the business world. A pioneer in the field of Contract Manpower, Readymix Concrete and the rehabilitation business, Mr. Gulab Singh Rana has been an instrumental force driving the Group of businesses over the past 40 years. His vision is to bring more maturity into the expanding conglomerate and concentrate on building value for all stakeholders.`
    },
    od : {
        name: "Gaurav Singh Rana",
        img: "/img/md.jpg",
        position: "Director, GSL",
        desc: `Mr. Gaurav Singh Rana hails from Gurugram. He has completed his Masters in Management and Finance from the University of Sussex, United Kingdom. He is the eldest amongst 3 siblings and has always been fascinated by the business world. A prolific and outstanding leader, he has led the group’s strategic thrust to create multiple drivers of growth that made a significant and growing contribution to the company and industry at large.
        A true entrepreneur and a visionary at heart and having identified the need to incorporate the use of technology in a field that is heavily dominated by manpower, he has helped shape the future course of business in the field of contractual manpower.
        A firm practitioner of Corporate Social Responsibility (CSR), he has institutionalized the concept of caring and giving at GS Logistics. Mr Gaurav has been affiliated with the non-profit charitable organization GS Foundation which strives to improve the health and wellbeing of members of marginalized societies by providing free health services. Over the years the NGO has covered thousands of people and helped them attain better health and wellbeing.`
    }
}