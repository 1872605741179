import React from "react";
import { WarehouseManagement, HrSolutions, FaciltyManagement, LoadingUnloading, Sewerage } from "../component/icons";
import {OurStrenght, ServiceSnapShot, RecruitmentProcess} from "../views/services";

export const serviceList = [
    {
        title: `Warehouse\nManagement`,
        desc: "Warehouse management: Total control of the control of the day-to-day operations of a warehouse, such as the shipping, receiving, put-away, and picking of goods.",
        img: "/img/tr1.jpeg",
        icon: <WarehouseManagement fontSize="large" />,
        data: <> <OurStrenght /> <ServiceSnapShot/></>
    },
    {
        title: `HR\nSolutions`,
        desc: "configurable to meet the needs of employees, so they can focus on meeting the needs of our corporate clients. Moreover, because GS Logistics aids multiple aspects of our business, such as simplifying complex tax codes and assisting with compliance, our HR team will have more time to focus on your people management.",
        img: "/img/tr2.jpeg",
        icon: <HrSolutions fontSize="large" />,
        data: <RecruitmentProcess/>
    },
    // {
    //     title: `Facility\nManagement`,
    //     desc: "GS Logistics encompasses multiple disciplines to ensure functionality, comfort, safety, and efficiency of the built environment by integrating people, place, process, and technology.",
    //     img: "/img/tr5.jpeg",
    //     icon: <FaciltyManagement fontSize="large" />,
    //     data: ""
    // },
    // {
    //     title: `Loading &\nUnloading`,
    //     desc: "A vehicle for the commercial purpose of the delivery of goods to or the receipt of goods from adjacent premises, the volume, weight or bulk of the goods being such that it would not be reasonable to expect the goods to be otherwise delivered or received",
    //     img: "/img/about2.png",
    //     icon: <LoadingUnloading fontSize="large" />,
    //     data: ""
    // },
    {
        title: `Rehabilitation &\nDesilting of Underground\nPiplelines`,
        desc: "Rehabilitation is a cost-effective way to improve the flow of sewerage/water in the existing line which has been damaged by roots, deposits, cracks, etc. It removes the need for relocation of other utilities in the vicinity.",
        img: "/img/sw.jpg",
        icon: <Sewerage fontSize="large" />,
        data: ""
    }
]

export const serviceListSort = [
    {
        title: `Warehouse\nManagement`,
        desc: "Warehouse management: Total control of the control of the day-to-day...",
        icon: <WarehouseManagement fontSize="large" />
    },
    {
        title: `HR\nSolutions`,
        desc: "configurable to meet the needs of employees, so they can focus...",
        icon: <HrSolutions fontSize="large" />
    },
    // {
    //     title: `Facility\nManagement`,
    //     desc: "GS Logistics encompasses multiple disciplines to ensure...",
    //     icon: <FaciltyManagement fontSize="large" />
    // },
    // {
    //     title: `Loading &\nUnloading`,
    //     desc: "A vehicle for the commercial purpose of the delivery of goods...",
    //     icon: <LoadingUnloading fontSize="large" />
    // },
    {
        title: `Rehabilitation &\nDesilting of Underground\nPiplelines`,
        desc: "Rehabilitation is a cost-effective way to improve the flow of...",
        icon: <Sewerage fontSize="large" />
    }
]

export const snapShot = [
    {
        title: "Total Order Handled Per Year in monetary terms",
        number: "Plus Rs. 7000 Crores/Year"
    },
    {
        title: "Total Warehouse area Managed for clients",
        number: "700,000 SqFeet"
    },
    {
        title: "Total SKU",
        number: "150,000+"
    },
    {
        title: "Total Manpower under Warehousing Operations",
        number: "2000 Employees"
    },
    {
        title: "Total Vehicles Received/Day",
        number: "300 Vehicles"
    },
    {
        title: "Total CMT dispatched/day",
        number: "4500 CMT"
    },
    {
        title: "Total Vehicles Dispatched/day",
        number: "170 Per Day"
    }
]

export const recruitmentProcess = [
    {
        title: "Knowing The Organization In & Out",
        desc: "History, Culture and Operations are the key stakeholders in understanding the company better. We would go through them first to facilitate a better process."
    },
    {
        title: "Applicant Details",
        desc: `Based on discussions the position specification document will be prepared and would be shared with the serious candidates for the position. Document will contain: Competencies, Experience, Background, Personal traits.
        The position specification will be reviewed with the client and serve as a guide in determining whom we will contact during the course of the search.
        The final position description will be presented for formal approval.`
    },
    {
        title: "Finding A Candidate",
        desc: `Upon approval of the Position Specification by the client, we will begin to search for candidates by following the below activities: Targeted Search, Database Review, Internally Generated Candidates.`
    },
    {
        title: "Evaluation And Shortlisting",
        desc: `On  evaluation candidates will be shortlisted on skill sets, experience and their interest in the mandate. ( In addition to personal interviews, we may use independent 3rd party Psychometric assessment tools at an extra fee).
        For the shortlisted candidates detailed resume & evaluation summary will be prepared, to go on with the interview process.`
    },
    {
        title: "Interviews",
        desc: `For the shortlisted candidates Interviews would be conducted with your representatives. Based on the meetings a final shortlist would be made.`
    },
    {
        title: "Checks of Successful Candidates",
        desc: "Before the final offer is extended, detailed references would be done for the candidates you wish to hire.( On extra fee background checks can be done by an independent 3rd party agency.)"
    },
    {
        title: "Negotiation",
        desc: `On further need GS Logistics would give assistance in competitive employment offers based on client compensation strategy and market realities.
        On behalf of the client, we can present the offer and explain it to the selected candidate. Throughout this final stage of the search process, GS Logistics  represents the client.`
    },
    {
        title: "Follow up",
        desc: `GS Logistics  remains in touch with the hired candidate between 60 and 90 days after he/ she has started on the assignment.`
    },
]