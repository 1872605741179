import React, {Component} from "react";
import { Box, Typography, Container, Grid, Hidden } from "@material-ui/core";
import {serviceList, snapShot, recruitmentProcess} from "../data/services";
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import "../sass/services.sass";
import Tabs from "../component/tabs/tabs";
import { Link } from "react-router-dom";
import { ourStrength } from "../data/about";

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.outerWidth,
            height: window.outerHeight
        };
    }
    componentDidMount = () =>  window.addEventListener("resize", this.updateDimensions);
    updateDimensions = () =>{
        this.setState({
            width: window.outerWidth,
            height: window.outerHeight
        });
    }
    render() {
        return(
            <Box width="100%" bgcolor="var(--white)">
                <Banner This={this} />
                <ServicesText This={this} />
                <ServicesList This={this} />
            </Box>
        )
    }
}

export default Services;

const Banner = (props) => {
    const {This} = props;
    const ml = (This.state.width - 1280) / 2;
    return(
        <Box position="relative" className="about-banner about-bg-1 pb80" minHeight={700} width="100%" height="100vh" bgcolor="var(--secondary)">
            <Container maxWidth="lg" style={{height: "100%"}}>
                <Grid container style={{height: "100%"}}>
                    <Grid className="pt80 mb30" container alignItems="center" item xs={12} style={{color: "var(--white)"}}>
                        <Grid item style={{maxWidth: "800px", margin: "0 auto"}}>
                            <Typography align="center" className="f_w-900 mb30" variant="h2" component="h1">SERVICES</Typography>
                            <Typography align="center" variant="subtitle1" component="p">Every client has a specific and special needs. GSL understands and on that we provide In-house Job contracts for Skilled, semi Skilled and Unskilled Manpower.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

const ServicesText = (props) => {
    const {This} = props;
    return(
        <Box className="pt100">
            <Box className="pt60">
                <Container maxWidth="lg">
                    <Typography align="center" variant="h6" color="primary">SERVICES</Typography>
                    <Typography align="center" variant="h3" className="f_w-600 mb20" style={{color: "var(--thirdColor)"}}>The Services We Provide</Typography>
                    <Typography align="center" variant="body1" className="f_w-500" style={{color: "var(--thirdColor)", maxWidth: 900, margin: "0 auto"}}>GSL expertise in executing In-house job contracts given to us by our clients for which we have Skilled, Semi-Skilled and Unskilled Manpower. For executing these job contracts we have a pool of qualified Supervisors and Managers experienced in various streams of the industry to manage the operations and to assist our clients to reach their goals.</Typography>
                </Container>
            </Box>
        </Box>
    )
}

const ServicesLists = (props) => {
    return(
        <>
            <Box className="pt100">
                <Box className="pt60">
                    <Container maxWidth="lg">
                        <Grid container spacing={2} justify="center">
                            {serviceList.map(x => (
                                <Grid container item lg={2} sm={6} md={4} xs={12} justify="center" style={{cursor: "pointer"}}>
                                    <Grid item className="service-click" style={{backgroundColor: "var(--disabled)", color: "var(--thirdColor)", width: "100%"}}>
                                        <Box position="relative" className="pl40 pr20 pt20 pb20">
                                            {x.icon}
                                            <Typography variant="body1" className="f_w-500 mt10 mb10" style={{whiteSpace: 'pre-line'}}>{x.title}</Typography>
                                            <Box position="absolute" className="line" left={0} bottom="10%" width={4} height="40%" style={{backgroundColor: "var(--thirdColor)"}} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Box>
            </Box>
            {serviceList.map(x => (
                <Box position="relative" className="mt100 pt60 sub-service-single" maxWidth={1280 + 120} margin="0 auto" style={{backgroundImage: "url('/img/about2.png')"}}>
                    <Container maxWidth="lg">
                        <Grid container direction="row-reverse">
                            <Grid item xs={4}>
                                <Box className="ml40 mt50 mb100">
                                    <Box position="relative">
                                        <Box position="absolute" className="shadow-bg" />
                                        <Box position="relative" borderRadius={40} zIndex={2} bgcolor="var(--primary)" color="var(--white)" className="p40 pt80 pb80">
                                            <Typography variant="h4" className="f_w-500 mb20">{x.title}</Typography>
                                            <Typography variant="body1" className="f_w-300 pr40">{x.desc}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            ))}
        </>
    )
}

const ServicesList = (props) => {
    const {This} = props;
    const currentService = (This.props.location.hash.substring(1, This.props.location.hash.length) || 0);
    return(
        <>
            <Link id="#0" to="/services#0" />
            <Tabs loadTab={serviceList[currentService].title}>
                {serviceList.map(x => (
                    <Box label={x.title} icon={x.icon}>
                        <Box>
                            <Box position="relative" className="mt100 pt60 sub-service-single" maxWidth={1280 + 120} margin="0 auto" style={{backgroundImage: `url('${x.img}')`}}>
                                <Container maxWidth="lg">
                                    <Grid container direction="row-reverse">
                                        <Grid item sm={4} xs={12}>
                                            <Box className="ml40 mt50 mb100">
                                                <Box position="relative">
                                                    <Box position="absolute" className="shadow-bg" />
                                                    <Box position="relative" borderRadius={40} zIndex={2} bgcolor="var(--primary)" color="var(--white)" className="p40 pt80 pb80">
                                                        <Typography variant="h4" className="f_w-500 mb20">{x.title}</Typography>
                                                        <Typography variant="body1" className="f_w-300 pr40">{x.desc}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                            {x.data}
                        </Box>
                    </Box>
                ))}
            </Tabs>
        </>
    )
}

export const ServiceSnapShot = (props) => {
    return(
        <Box className="mt60 pt20">
            <Container maxWidth="lg">
                <Grid container spacing={5} justify="center">
                    {snapShot.map(x => (
                        <Grid container item lg={3} sm={6} md={4} xs={12} justify="center" style={{cursor: "pointer"}}>
                            <Grid container alignItems="center" item className="service-hover" style={{backgroundColor: "var(--disabled)", color: "var(--thirdColor)", width: "100%"}}>
                                <Box width="100%" position="relative" className="pl20 pr20 pt60 pb60">
                                    <Typography variant="body1" className="f_w-500 mt10 mb10">{x.title}</Typography>
                                    <Typography variant="h4" className="f_w-600 pr40">{x.number}</Typography>
                                    <Box position="absolute" className="line" left={0} bottom="10%" width={4} height="40%" style={{backgroundColor: "var(--thirdColor)"}} />
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}

export const RecruitmentProcess = (props) => {
    return(
        <Box className="mt100 pt60">
            <Container maxWidth="lg">
                <Typography align="center" variant="h6" color="primary">Recruitment Process</Typography>
                <Typography align="center" variant="h3" className="f_w-600 mb20" style={{color: "var(--thirdColor)"}}>8 Sigma Recruitment Process</Typography>
                <Typography align="center" variant="body1" className="f_w-500" style={{color: "var(--thirdColor)", maxWidth: 900, margin: "0 auto"}}>GSL follows a well-defined process for all its recruitments, whether it be for contractual manpower, temporary manpower, or permanent manpower.</Typography>
                <Grid container spacing={8} justify="center" className="mt80">
                    {recruitmentProcess.map((x, i) => (
                        <Grid container item lg={4} sm={6} md={4} xs={12} justify="center" style={{cursor: "pointer"}}>
                            <Grid container alignItems="center" item className="service-hover" style={{backgroundColor: "var(--disabled)", color: "var(--thirdColor)", width: "100%", position: "relative"}}>
                                <Box width="100%" className="pl40 pr20 pt60 pb60">
                                    <Typography variant="h6" className="f_w-500 mt10 mb10">{x.title}</Typography>
                                    <Box position="absolute" top={-30} bgcolor="var(--white)" border="3px solid var(--thirdColor)" borderRadius="400px" height={60} width={60} display="flex" justifyContent="center" alignItems="center">
                                        <Typography variant="h5" className="f_w-600">{i + 1}</Typography>
                                    </Box>
                                    <Box className="process-desc" position="absolute" bottom={0} left={0} right={0} bgcolor="var(--primary)" color="var(--white)">
                                        <Typography variant="h6" className="f_w-500 mt10 mb10">{x.title}</Typography>
                                        <Typography variant="body2" className="f_w-300">{x.desc}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}

export const OurStrenght = (props) => {
    return(
        <Box className="pt100">
            <Container maxWidth="lg" className="pt60">
                <Typography align="center" variant="h3" className="f_w-600 mb20" style={{color: "var(--thirdColor)"}}>Our Strength</Typography>
                <Grid container className="pt40" spacing={3}>
                    {ourStrength.map(x => (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box display="flex" alignItems="center" position="relative" width="100%" minHeight={80} bgcolor="var(--disabled)">
                                <Box position="absolute" left={0} top={0} height="100%" width="3px" bgcolor="var(--thirdColor)" />
                                <Typography variant="body2" className="f_w-500 p20" style={{color: "var(--thirdColor)"}}>{x}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}