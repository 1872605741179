import React, {Component} from 'react';
import { Box, Typography, Grid } from "@material-ui/core";
import HomeWorkIcon from '@material-ui/icons/HomeWork';

class Tab extends Component {
    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
    }
    render() {
        const {onClick, props: {activeTab, label, icon}} = this;
        let className = 'service-click';
        if (activeTab === label) {
            className += ' active'
        }
        return(
            <Grid container item lg={2} sm={6} md={4} xs={12} justify="center" style={{cursor: "pointer"}}>
                <Grid onClick={onClick} item className={className} style={{backgroundColor: "var(--disabled)", color: "var(--thirdColor)", width: "100%"}}>
                    <Box position="relative" className="pl40 pr20 pt20 pb20">
                        {icon}
                        <Typography variant="body1" className="f_w-500 mt10 mb10" style={{whiteSpace: 'pre-line'}}>{label}</Typography>
                        <Box position="absolute" className="line" left={0} bottom="10%" width={4} height="40%" style={{backgroundColor: "var(--thirdColor)"}} />
                    </Box>
                </Grid>
            </Grid>
        )
    }
}
export default Tab;