export const covidImg = [
    {
        src: "/img/csr/corona/1.jpg",
        alt: "Thermameter Distribution due to covid 19 image 1"
    },
    {
        src: "/img/csr/corona/2.jpg",
        alt: "Thermameter Distribution due to covid 19 image 2"
    },
    {
        src: "/img/csr/corona/3.jpg",
        alt: "Thermameter Distribution due to covid 19 image 3"
    },
    {
        src: "/img/csr/corona/4.jpg",
        alt: "Thermameter Distribution due to covid 19 image 4"
    },
    {
        src: "/img/csr/corona/5.jpg",
        alt: "Thermameter Distribution due to covid 19 image 5"
    },
    {
        src: "/img/csr/corona/6.jpg",
        alt: "Thermameter Distribution due to covid 19 image 6"
    }
]

export const csrHealthCheckup = [
    {
        src: "/img/csr/health-checkup/1.jpg",
        alt: "Thermameter Distribution due to covid 19 image 1"
    },
    {
        src: "/img/csr/health-checkup/2.jpg",
        alt: "Thermameter Distribution due to covid 19 image 2"
    },
    {
        src: "/img/csr/health-checkup/3.jpg",
        alt: "Thermameter Distribution due to covid 19 image 3"
    },
    {
        src: "/img/csr/health-checkup/4.jpg",
        alt: "Thermameter Distribution due to covid 19 image 4"
    },
    {
        src: "/img/csr/health-checkup/5.jpg",
        alt: "Thermameter Distribution due to covid 19 image 5"
    }
]