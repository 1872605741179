export const aboutList = [
    {text: "About Us", url: "/about-us"},
    {text: "Founders", url: "/about-us"},
    {text: "Clients", url: "/clients"}
]

export const obligation = [
    "The Contract Labor Regulation and Abolition Act, 1970.",
    "The Payment of Wages Act,1936.",
    "The Payment of Bonus Act,1965.",
    "The Equal Remuneration Act, 1976",
    "Factories Act 1948",
    "The Contract Labour (Regulation & Abolition) Act, 1971",
    "Industrial Disputes Act. & TradeUnion Act",
    "Maternity Benefits Act",
    "The Employees Provident Fund Act, 1952.",
    "PF Code No. GN/GGN/0026736000",
    "The Employees State Insurance Act,1948.",
    "ESI No. 69000320570000999",
    "The Income Tax Act - PAN No. AACCG2540A",
    "The Service Tax Act - AACCG2540AST001",
    "GST No. – 06AACCG2540A1ZD"
]

export const commitments = [
    "Conducting our business with respect and care for people and the environment",
    "Responsible utilisation of natural resources",
    "Continually improving our safety, health and environmental performance",
    "Complying, as a minimum, with all applicable legal and other agreed requirements",
    "Promoting dialogue with stakeholders about safety, health and environmental performance"
]

export const achiveCommitments = [
    "Implementing safety, health, environmental management system",
    "Informing and appropriately training all employees and contractors on safety, health and environmental matters",
    "Responding effectively to safety, health and environmental emergencies involving our activities and products",
    "As far as reasonably practicable, providing appropriate resources required to implement the above.",
    "Ensure that the health, safety and environmental policy is reviewed at periods not exceeding three years from the effective date or by a date set by the Chief Executive Officer."
]

export const ourStrength = [
    "More than 37 years of experience in Warehousing operations.",
    "Our team of 2000 well trained and experienced employees.",
    "Low rate of attrition and absenteeism leading to higher productivity.",
    "Constant training",
    "Focus on Employee multi-tasking.",
    "Continuous Stock Audits and strong inventory management.",
    "Planning, Prioritizing and Implementation.",
    "Working on the 5S principle for effective warehouse management.",
    "Focus on Safety",
    "Continuous Improvement."
]