import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
    palette:{
        common:{
            black: "#000000",
            white: "#ffffff"
        },
        primary: {
            main: "#DA2424"
        },
        secondary: {
            main: "#A10000"
        },
        thirdColor: {
            main: "#4E4C58"
        }
    }
});