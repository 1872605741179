import React, {Component} from "react";
import { Box, Typography, Container, Grid, Hidden } from "@material-ui/core";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.outerWidth,
            height: window.outerHeight
        };
    }
    componentDidMount = () =>  window.addEventListener("resize", this.updateDimensions);
    updateDimensions = () =>{
        this.setState({
            width: window.outerWidth,
            height: window.outerHeight
        });
    }
    render() {
        return(
            <Box width="100%" bgcolor="var(--white)">
                <Banner This={this} />
                <ClientList This={this} />
            </Box>
        )
    }
}

export default Clients;

const Banner = (props) => {
    const {This} = props;
    const ml = (This.state.width - 1280) / 2;
    return(
        <Box position="relative" className="about-banner about-bg-1 pb80" minHeight={700} width="100%" height="100vh" bgcolor="var(--secondary)">
            <Container maxWidth="lg" style={{height: "100%"}}>
                <Grid container style={{height: "100%"}}>
                    <Grid className="pt80 mb30" container alignItems="center" item xs={12} style={{color: "var(--white)"}}>
                        <Grid item style={{maxWidth: "800px", margin: "0 auto"}}>
                            <Typography align="center" className="f_w-900 mb30" variant="h2" component="h1">Clients</Typography>
                            {/* <Typography align="center" variant="subtitle1" component="p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Typography> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

const ClientList = (props) => {
    const {This} = props;
    const ml = (This.state.width - 1280) / 2;
    const logos = ["logo1", "logo2", "logo3", "logo4", "logo5", "logo6", "logo7", "logo8", "logo9", "logo10", "logo11", "logo12", "logo13", "logo14", "logo15", "logo16", "logo17", "logo18"];
    return(
        <Box className="pt100">
            <Box className="pt60">
                <Container maxWidth="lg">
                    <Grid container style={{textAlign: "center"}} spacing={3}>
                        {logos.map(m => (
                            <Grid item xs={4}>
                                <Box className="p60" bgcolor="var(--disabled)">
                                    <img src={`/img/logo/${m}.png`} alt={`logo for company ${m}`} style={{width: "100%", height: "auto", objectFit: "contain", maxHeight: 100}} />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

