import React, { Component, Children } from "react";
import Navigation from "../component/layout/nav";
import Footer from "../component/layout/footer";
import { CssBaseline } from "@material-ui/core";


class Layout extends Component {
    render(){
        return(
            <>
                <CssBaseline />
                <Navigation />
                    {this.props.children}
                <Footer />
            </>
        );
    }
}

export default Layout;