import React, {Component} from "react";
import { Dialog, DialogContent, Box, Typography } from "@material-ui/core";

class FoundersDialog extends Component{
    render() {
        const {This} = this.props;
        return(
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={This.state.open}
                onClose={This.handleClose}
            >
                <DialogContent>
                    <Box className="p20">
                        <Box textAlign="center" width="100%" className="mb30">
                            <img src={This.state.dialogContent.img} alt="Gs Logistics Pvt. Ltd. Ceo" style={{width: "280px", height: "280px", objectFit: "cover", borderRadius: "400px 400px 400px 0"}} />
                        </Box>
                        <Typography display="block" variant="h5" className="f_w-500 g-full">{This.state.dialogContent.name}</Typography>
                        <Typography display="block" variant="caption" className="f_w-500 g-full" style={{color: "var(--thirdColor)"}}>{This.state.dialogContent.position}</Typography>
                        <Typography display="block" variant="body1" className="f_w-400 g-full mt15" style={{whiteSpace: 'pre-line'}}>{This.state.dialogContent.desc}</Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
}

export default FoundersDialog;