import {createStore, combineReducers, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
// import guestReducer from "./reducers/guestReducer";
import uiReducer from "./reducers/uiReducer";

/* Middleware */
const middleware = [thunk];
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));
/* Middleware */

const reducers = combineReducers({
    // guest: guestReducer,
    UI: uiReducer 
});
const Store = createStore(reducers, {}, enhancer);

export default Store;