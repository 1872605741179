import React, {Component} from "react";
import {Box, Container, Grid, Typography, TextField, Button, Fab, List, ListItem, ListItemText, ListItemIcon, Link as ILink} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import {serviceList} from "../../data/services";
import {aboutList} from "../../data/about";
import { Link } from "react-router-dom";

class Footer extends Component {
    render() {
        return(
            <Box className="pt100" bgcolor="var(--white)">
                <Container className="pt80">
                    <Box border="1px solid var(--thirdColor)" />
                    <Box className="pt60 pb30">
                        <Grid container justify="space-between">
                            <Grid item container xs={12} md={6} lg={4}>
                                <Grid item xs={12}>
                                    <Box height={85} className="mb20">
                                        <img src="/logo.png" alt="company logo" style={{height: "100%", width: "auto"}} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{color: "var(--thirdColor)"}} variant="h6" className="f_w-500">Subscribe to our newsletter</Typography>
                                    <Grid container item className="mt10">
                                        <Grid item style={{flex: 1}}>
                                            <Box bgcolor="#e8e8e8">
                                                <TextField
                                                    size="medium"
                                                    variant="filled"
                                                    fullWidth
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item style={{flex: 0}} style={{backgroundColor: "#AEAEAE"}}>
                                            <Box height="100%">
                                                <Button style={{height:"100%"}}>
                                                    <ArrowForwardIcon fontSize="large" />
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="mt30 mb30">
                                    <ILink href="https://www.facebook.com/gslogisticsindia/" target="_blank">
                                        <Fab size="medium" className="shadow-none mr20" style={{backgroundColor: "var(--disabled)"}}><FacebookIcon /></Fab>
                                    </ILink>
                                    <Fab size="medium" className="shadow-none mr20" style={{backgroundColor: "var(--disabled)"}}><TwitterIcon /></Fab>
                                    <Fab size="medium" className="shadow-none" style={{backgroundColor: "var(--disabled)"}}><LinkedInIcon /></Fab>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} md={6} lg={4}>
                                <Grid item xs={6} className="pr30">
                                    <Typography style={{color: "var(--thirdColor)"}} variant="h6" className="f_w-500">Services</Typography>
                                    <List>
                                        {serviceList.map((m, i) => (
                                            <Link to={`/services#${i}`} style={{textDecoration: "none", color:"var(--thirdColor)"}}>
                                                <ListItem style={{paddingLeft: 0}} className="pt5">
                                                    <ListItemIcon className="pr5" style={{minWidth: "initial"}}>
                                                        <ArrowForwardIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primaryTypographyProps={{variant: "body2", noWrap: true}} primary={m.title} />
                                                </ListItem>
                                            </Link>
                                        ))}
                                    </List>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{color: "var(--thirdColor)"}} variant="h6" className="f_w-500">Company</Typography>
                                    <List>
                                        {aboutList.map(m => (
                                            <Link to={m.url} style={{textDecoration: "none", color:"var(--thirdColor)"}}>
                                                <ListItem style={{paddingLeft: 0}} className="pt5">
                                                    <ListItemIcon className="pr5" style={{minWidth: "initial"}}>
                                                        <ArrowForwardIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primaryTypographyProps={{variant: "body2", noWrap: true}} primary={m.text} />
                                                </ListItem>
                                            </Link>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box border="1px solid var(--thirdColor)" />
                    <Box className="pt15 pb15">
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography variant="body2">Terms & Conditions | Privacy policy | Cookies</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">All rights reserved 2020 Gs Logistics Pvt. Ltd.</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        );
    }
}

export default Footer;
