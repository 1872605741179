import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { useScrollTrigger, Grid, Container, AppBar, Toolbar, Box, List, ListItem, ListItemText, IconButton, Hidden, withWidth, SwipeableDrawer } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';

import { Menu } from "../../data/menu";
import "../../sass/navigation.sass";

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
    }
    onMenuOpen = () => {
        this.setState({menuOpen: !this.state.menuOpen});
    }
    componentDidUpdate = (e) => onPageChange(e, this);
    render(){
        const {width, location} = this.props;
        const page = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);       
        return(
            <>
                <ElevationScroll page={page} {...this.props}>
                    <AppBar position="fixed" color="transparent" className={`shadow-none`}>
                        <Toolbar className={`${width !== "xs" ? "d-padding" : "m-padding"}`}>
                            <Hidden smDown >
                                <Container maxWidth="lg">
                                    <Desktop pathname={this.props.location.pathname} />
                                </Container>
                            </Hidden>
                            <Hidden mdUp>
                                <Mobile onMenuOpen={this.onMenuOpen} />
                                <MobileMenu menuOpen={this.state.menuOpen} onMenuOpen={this.onMenuOpen} pathname={this.props.location.pathname} />
                            </Hidden>
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
                {/* <Box className={width !== "xs" ? "pb20 mb100" : "pb5 mb50"} /> */}
            </>
        );
    }
}

export default withWidth()(withRouter(Navigation));

const Desktop = (props) => {
    const {pathname} = props;
    return (
        <Grid container>
            <Grid item>
                <Box height="100%" display="flex" alignItems="center">
                    <Link to="/" style={{textDecoration: "none", color:"var(--thirdColor)"}}>
                        <img alt="Gs Logistics" style={{height: 100}} src="/logo.png" />
                    </Link>
                </Box>
            </Grid>
            <Grid container className="g-d-f j_c-c" item style={{flex: 1}}>
                <List className="g-d-f navigation-ul">
                    {Menu.map(m => (
                        <ListItem key={`${m.label}-001`} className={`pl25 pr25${matchPathname(pathname, m)}`} style={{width:"auto"}}>
                            <Link to={m.pathname} className="g-t_d-n c-black">
                                <ListItemText classes={{primary: "f_w-500"}} primaryTypographyProps={{variant: "body2"}} primary={m.label} />
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    )
}

const Mobile = (props) => {
    return(
        <Grid container style={{position: "relative"}}>
            <Grid item style={{position: "absolute", top: 0, left: 0}}>
                <IconButton edge="start" onClick={props.onMenuOpen}>
                    <MenuIcon />
                </IconButton>
            </Grid>
            <Grid item style={{flex: 1}}>
                <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                    <Link  to="/" style={{textDecoration: "none", color:"var(--thirdColor)"}}>
                        <img alt="Gs Logistics" src="/logo.png" style={{maxHeight: 48}} />
                    </Link>
                </Box>
            </Grid>
        </Grid>
    );
}

const MobileMenu = (props) => {
    const {menuOpen, onMenuOpen, pathname} = props;
    return(
        <SwipeableDrawer anchor="left" open={menuOpen} onClose={onMenuOpen} onOpen={onMenuOpen}>
            <List style={{width: 250}} className="pt60 navigation-ul-mobile">
                {Menu.map(m => (
                    <ListItem key={`${m.label}-001`} className={matchPathname(pathname, m)}>
                        <Link to={m.pathname} className="g-t_d-n c-black g-full">
                            <ListItemText classes={{primary: "f_w-500"}} primaryTypographyProps={{variant: "body2"}} primary={m.label} />
                        </Link>
                    </ListItem>
                ))}
            </List>
        </SwipeableDrawer>
    );
}

const matchPathname = (pathname, m) => {
    if (pathname.length === 1 && pathname === m.pathname) {
        return " nav-active";
    }else{
        return pathname.indexOf(m.pathname) >= 1 ? " nav-active" : ""
    }
}

const onPageChange = (e, thisUse) => {
    if (e.history.location.pathname !== e.location.pathname) {
        if (thisUse.state.menuOpen) {
            thisUse.setState({ menuOpen: false });
        }
    }
}

const ElevationScroll = (props) => {
    const { children, window, page } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined
    });
    return React.cloneElement(children, {
      elevation: trigger ? 0 : 0,
      style: trigger ? { backgroundColor: "var(--white)" } : { backgroundColor: "transparent" },
      className: trigger ? "" : `shadow-none ${page}`
    });
}