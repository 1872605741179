import {TEST} from "../types";

const initialState = {
    test: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case TEST:
            return{
                test: true
            }
        default:
            return state;
    }
}