import React, {Component} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";

import theme from "./theme";
import store from "./redux/store";
import ScrollToTop from "./functions/scrollToTop";

import Layout from "./views/layout";
import Home from "./views/home";
import AboutUs from "./views/about-us";
import Services from "./views/services";
import Clients from "./views/clients";
import CSR from "./views/csr";
import ContactUs from "./views/contact-us";

class App extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <Router>
                        <ScrollToTop />
                        <Layout>
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route exact path="/about-us" component={AboutUs} />
                                <Route exact path="/services" component={Services} />
                                <Route exact path="/clients" component={Clients} />
                                <Route exact path="/csr" component={CSR} />
                                <Route exact path="/contact-us" component={ContactUs} />
                            </Switch>
                        </Layout>
                    </Router>
                </Provider>
            </ThemeProvider>
        );
    }
}

export default App;
