import React, {Component} from "react";
import { Box, Typography, Grid, Container, TextField, Button, Fab } from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';


class ContactUs extends Component {
    render() {
        return(
            <Box width="100%" className="pt50" bgcolor="var(--white)">
                <Container>
                    <Box color="var(--thirdColor)" className="pt100 mt100">
                        <Grid container>
                            <Grid item md={6} sm={12} xs={12}>
                                <Box>
                                    <Typography variant="h3" className="f_w-600">Where ?</Typography>
                                    <Typography variant="body1" className="f_w-400 mt10" style={{whiteSpace: 'pre-line'}}>{`SCO 35-36,\nSecond floor, Sector 12 A,\nCommercial market,\nGurugram, Haryana 122001`}</Typography>
                                </Box>
                                <Box className="mt100">
                                    <Typography variant="h3" className="f_w-600">When ?</Typography>
                                    <Typography variant="body1" className="f_w-400 mt10" style={{whiteSpace: 'pre-line'}}>{`6 days a week,\n10:00 am - 6:00 pm`}</Typography>
                                </Box>
                                <Box className="mt100 mb100">
                                    <Typography variant="h3" className="f_w-600">Get In Touch</Typography>
                                    <Typography variant="body1" className="f_w-400 mt10" style={{whiteSpace: 'pre-line'}}>{`info@gslogistics.net.in\n+91 0124 426 5265`}</Typography>
                                    <Box className="mt20">
                                        <Fab size="medium" className="shadow-none mr20" style={{backgroundColor: "var(--disabled)"}}><FacebookIcon /></Fab>
                                        <Fab size="medium" className="shadow-none mr20" style={{backgroundColor: "var(--disabled)"}}><TwitterIcon /></Fab>
                                        <Fab size="medium" className="shadow-none" style={{backgroundColor: "var(--disabled)"}}><LinkedInIcon /></Fab>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <Box width="100%">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.3612095205062!2d77.03356131507891!3d28.46866668248275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjjCsDI4JzA3LjIiTiA3N8KwMDInMDguNyJF!5e0!3m2!1sen!2sin!4v1580061773368!5m2!1sen!2sin" frameborder="0" style={{border:0, width: "100%", height: "300px", backgroundColor: "var(--disabled)"}}></iframe>
                                </Box>
                                <Box className="mt40">
                                    <Box>
                                        <TextField size="medium" variant="outlined" fullWidth placeholder="Name"/>
                                    </Box>
                                    <Box className="mt30">
                                        <TextField size="medium" variant="outlined" fullWidth placeholder="Email"/>
                                    </Box>
                                    <Box className="mt30">
                                        <TextField size="medium" variant="outlined" fullWidth placeholder="Message" multiline rows="5"/>
                                    </Box>
                                    <Box className="mt30">
                                        <Button size="large" variant="contained" color="primary">Send Message</Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        )
    }
}

export default ContactUs;