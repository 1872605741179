import React, {Component} from 'react';
import Tab from "./tab";
import { Grid, Box, Container } from '@material-ui/core';

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.props.loadTab
        }
    }
    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    }
    render() {
        const { onClickTabItem, props: { children, loadTab }, state: { activeTab }} = this;
        return(
            <>
                <Box className="pt100">
                    <Box className="pt60">
                        <Container maxWidth="lg">
                            <Grid container spacing={2} justify="center">
                                {children.map((child) => {
                                    const {label, icon} = child.props;
                                    return(
                                        <Tab icon={icon} activeTab={activeTab} key={label} label={label} onClick={onClickTabItem} />
                                    )
                                })}
                            </Grid>
                        </Container>
                    </Box>
                </Box>
                {children.map((child) => {
                    if (child.props.label !== activeTab) return undefined;
                    return child.props.children;
                })}
            </>
        );
    }
}

export default Tabs;