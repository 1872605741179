import React , {Component} from "react";
import {Link} from "react-router-dom";
import { Container, Box, Grid, Typography, Button, Hidden } from "@material-ui/core";
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import "../sass/home.sass";
import { serviceListSort } from "../data/services";
import { SocialSecurity, PayRaise, HealthInsurance } from "../component/icons";

class Home extends Component{
    constructor(props) {
        super(props);
        this.state = {
            width: window.outerWidth,
            height: window.outerHeight
        };
    }
    componentDidMount = () =>  window.addEventListener("resize", this.updateDimensions);;
    updateDimensions = () =>{
        this.setState({
            width: window.outerWidth,
            height: window.outerHeight
        });
    }
    render() {
        return(
            <Box position="relative" width="100%" style={{backgroundColor: "var(--white)"}}>
                <Hidden smDown>
                    <Desktop This={this} />
                    <Common This={this} />
                    <AboutUsDesktop This={this} />
                    <RelationShip This={this} />
                </Hidden>
                <Hidden mdUp>
                    <Mobile This={this} />
                    <Common This={this} />
                    <AboutUsDesktop This={this} />
                    <RelationShip This={this} />
                </Hidden>
            </Box>
        )
    }
}
export default Home;

const Desktop = (props) => {
    const {This} = props;
    const logos = ["logo1", "logo2", "logo3", "logo4", "logo5", "logo6"];
    return(
        <>
            <Box className="banner-upper" position="relative" height={This.state.height} width="100%" style={{backgroundColor: "var(--white)", overflow: "hidden"}}>
                <Container maxWidth="lg" style={{backgroundColor: "var(--white)", height: "100%"}}>
                    <Grid container style={{height: "100%"}} alignItems="center">
                        <Grid item xs={12} sm={6} className="pr60">
                            <Typography className="f_w-900 mb30" variant="h2" component="h1">We help our clients in achieving their goals</Typography>
                            <Typography className="mb30" variant="subtitle1" component="p">GSL expertise lays in executing In-house job contracts given to us by our clients for which we have a pool of qualified manpower.</Typography>
                            <Link to="/about-us" style={{textDecoration: "none"}}>
                                <Button size="large" variant="contained" color="primary">Read More</Button>
                            </Link>
                        </Grid>
                        <Box position="absolute" zIndex={1} right={0} top={"50%"} height={This.state.height * 64 / 100} width={This.state.width * 50 / 100} style={{transform: "translateY(-50%)"}}>
                            <img src="/img/header.png" alt="website header" style={{width: "100%", height: "100%", objectFit: "cover"}} />
                            {/* <Box className="d-red-area-dot" position="absolute" top={0} right={0} bottom={0} width={(This.state.width - 1298) / 2} height="100%">
                                <Box width="100%" height="100%" />
                            </Box> */}
                        </Box>
                    </Grid>
                </Container>
            </Box>
            <Box className="d-banner-box">
                <Container className="d-banner-number" maxWidth="lg" style={{backgroundColor: "var(--white)"}}>
                    <Grid container className="mb100" item xs={9} style={{backgroundColor: "var(--primary)"}}>
                        <Grid item sm={4} className="pt40">
                            <Typography align="center" variant="h3" className="f_w-900 g-full pt20" style={{color: "var(--white)"}}>1983</Typography>
                            <Typography align="center" variant="h6" className="f_w-500 g-full pb20" style={{color: "var(--white)"}}>Journey Begins</Typography>
                        </Grid>
                        <Grid item sm={4} className="p40">
                            <Typography align="center" variant="h3" className="f_w-900 g-full pt20" style={{color: "var(--white)"}}>160 K</Typography>
                            <Typography align="center" variant="h6" className="f_w-500 g-full pb20" style={{color: "var(--white)"}}>Careers Made</Typography>
                        </Grid>
                        <Grid item sm={4} className="p40">
                            <Typography align="center" variant="h3" className="f_w-900 g-full pt20" style={{color: "var(--white)"}}>20</Typography>
                            <Typography align="center" variant="h6" className="f_w-500 g-full pb20" style={{color: "var(--white)"}}>Industries</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className="mb100 pt80 pb80">
                <Container maxWidth="lg">
                    <Grid container style={{flexWrap: "nowrap", textAlign: "center"}} spacing={3}>
                        {logos.map(m => (
                            <Grid item xs={12}>
                                <Box height={60}>
                                    <img src={`/img/logo/${m}.png`} alt={`logo for company ${m}`} style={{width: "auto", height: "100%", filter: "grayscale(1)", opacity: 0.9}} />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

const Mobile = (props) => {
    const {This} = props;
    return(
        <>
            <Grid container style={{ height: "100%", backgroundColor: "var(--white)", overflow: "hidden"}}>
                <Grid item xs={12} md={6} className="mt100 mb100">
                    <Container maxWidth="lg">
                        <Typography className="f_w-900 mb30" variant="h2" component="h1">We help our clients in achieving their goals</Typography>
                    </Container>
                    <Box position="relative" className="banner-image">
                        <Box position="relative" zIndex="1" width={This.state.width * 85 / 100} marginLeft="auto" style={{backgroundColor: "var(--white)"}}>
                            <Box height={This.state.height * 50 / 100} width="100%">
                                <img src="/img/header.png" alt="header for mobile" style={{width: "100%", height: "100%", objectFit: "cover"}} />
                            </Box>
                            <Typography className="pl15 mt40 mb30" variant="subtitle1" component="p">GSL expertise lays in executing In-house job contracts given to us by our clients for which we have a pool of qualified manpower</Typography>
                            <Box display="flex" width="100%" justifyContent="flex-end" className="pb80">
                                <Button size="large" variant="contained" className="mr25" color="primary">Read More</Button>
                            </Box>
                        </Box>
                        <Box position="absolute" className="img-red-area" zIndex="0" style={{backgroundColor: "var(--primary)"}} bottom={0} left={0} width="100%" height="60%" />
                    </Box>
                    <Box marginRight="auto" width={This.state.width * 85 / 100} padding={5} className="bg-banner-number" style={{backgroundColor: "var(--primary)"}}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={4}>
                                <Typography align="center" variant="h3" className="f_w-900 g-full" style={{color: "var(--white)"}}>1983</Typography>
                                <Typography align="center" variant="h6" className="f_w-500 g-full" style={{color: "var(--white)"}}>Journey Begins</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography align="center" variant="h3" className="f_w-900 g-full" style={{color: "var(--white)"}}>160 K</Typography>
                                <Typography align="center" variant="h6" className="f_w-500 g-full" style={{color: "var(--white)"}}>Careers Made</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography align="center" variant="h3" className="f_w-900 g-full" style={{color: "var(--white)"}}>20</Typography>
                                <Typography align="center" variant="h6" className="f_w-500 g-full" style={{color: "var(--white)"}}>Industries</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Box className="mb100 pt40 pb80">
                <Container maxWidth="lg">
                    <Grid container style={{flexWrap: "nowrap", textAlign: "center"}}>
                        <Grid item xs={12}>
                            <Box>
                                <img src={`/img/logo/logog.png`} alt="logo" style={{width: "100%", filter: "grayscale(1)", opacity: 0.9}} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

const Common = (props) => {
    return(
        <>
            <Box>
                <Typography align="center" variant="h6" color="primary">SERVICES</Typography>
                <Typography align="center" variant="h3" className="f_w-600 mb20" style={{color: "var(--thirdColor)"}}>We Provide Best Services</Typography>
                <Typography align="center" variant="body1" className="f_w-500" style={{color: "var(--thirdColor)", maxWidth: 700, margin: "0 auto"}}>GSL understands the need of the clients and accordingly provides job contracts for skilled semi skilled and unskilled manpower.</Typography>
            </Box>
            <Box className="mt60 pt20">
                <Container maxWidth="lg">
                    <Grid container spacing={5} justify="center">
                        {serviceListSort.map(x => (
                            <Grid container item lg={4} sm={6} md={4} xs={12} justify="center" style={{cursor: "pointer"}}>
                                <Grid item className="service-hover" style={{backgroundColor: "var(--disabled)", color: "var(--thirdColor)"}}>
                                    <Box position="relative" className="pl40 pr20 pt60 pb60">
                                        {x.icon}
                                        <Typography variant="h5" className="f_w-500 mt10 mb10">{x.title}</Typography>
                                        <Typography variant="body1" className="f_w-300 pr40">{x.desc}</Typography>
                                        <Box position="absolute" className="line" left={0} bottom="10%" width={4} height="40%" style={{backgroundColor: "var(--thirdColor)"}} />
                                    </Box>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

const AboutUsDesktop = (props) => {
    const {This} = props;
    return(
        <>
            <Box className="mt100 pt100 pb100 about-bg-1" maxWidth={1280 + 120} margin="0 auto" bgcolor="var(--secondary)">
                <Container maxWidth="lg" className="pt50 pb100">
                    <Grid container>
                        <Grid item sm={12} md={6} xs={12}>
                            <Hidden smDown>
                                <Box paddingRight={7} color="var(--white)">
                                    <Typography variant="h6" className="f_w-400">ABOUT US</Typography>
                                    <Typography variant="h3" className="f_w-600 mt20 mb30">We are leading Manpower & Job Outsourcing Company</Typography>
                                    <Typography variant="body1" className="f_w-400" style={{maxWidth: 700, margin: "0 auto"}}>GSL is one of the leading and reputed Manpower & Job Outsourcing Company with vast experience since 1983 , which caters to some of the leading National & International Companies to facilitate them to Cope up with changing times as per respective Industry needs.</Typography>
                                </Box>
                            </Hidden>
                            <Hidden mdUp>
                                <Box color="var(--white)">
                                    <Typography variant="h6" className="f_w-400">ABOUT US</Typography>
                                    <Typography variant="h3" className="f_w-600 mt20 mb30">We are leading Manpower & Job Outsourcing Company</Typography>
                                    <Typography variant="body1" className="f_w-400" style={{maxWidth: 700, margin: "0 auto"}}>GSL is one of the leading and reputed Manpower & Job Outsourcing Company with vast experience since 1983 , which caters to some of the leading National & International Companies to facilitate them to Cope up with changing times as per respective Industry needs.</Typography>
                                </Box>
                            </Hidden>
                        </Grid>
                        <Hidden smDown>
                            <Grid item sm={12} md={6} xs={12} style={{position: "absolute", right: 0}}>
                                <Box width="100%" className="pl100">
                                    <img src="/img/about1.png" alt="about us" style={{width: "100%"}} />
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Container>
            </Box>
            <Box position="relative" bgcolor="var(--white)" height={(This.state.height * 50 / 100) / 2} style={{marginBottom: ((This.state.height * 50 / 100) / 2) - 100}}>
                <Box className="about-bg-1" height="100%" maxWidth={1280 + 120} margin="0 auto" bgcolor="var(--secondary)">
                    <Container maxWidth="lg">
                        <Grid container>
                            <Grid item sm={12} md={6} xs={12} style={{position: "absolute", left: 0, top: -100}}>
                                <Box width="100%">
                                    <img src="/img/about2.png" alt="about us" style={{width: "100%", height: This.state.height * 50 / 100, objectFit: "cover"}}/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </>
    );
}

const RelationShip = (props) => {
    const list = [
        {
            title: "Health Insurance",
            desc: "All employees are covered under ESI. GS Logistics take care of all our employees under medical insurance too.",
            icon: <HealthInsurance fontSize="large"/>
        },
        {
            title: "Pay Raise",
            desc: "Pay is revised every year in accordance with the direction of the Government.",
            icon: <PayRaise fontSize="large"/>
        },
        {
            title:  "Social Security",
            desc: "All our employees are covered under the Provident Fund to ensure their social security.",
            icon: <SocialSecurity fontSize="large"/>
        }
    ];
    return(
        <Box className="pt100">
            <Container className="pt60">
                <Box maxWidth={600} margin="0 auto">
                    <Typography align="center" variant="h3" className="f_w-600 mb20" style={{color: "var(--thirdColor)"}}>Our Human Relationship & Staff Welfare</Typography>
                </Box>
                <Box marginTop="100px">
                    <Grid container>
                        <Grid container alignItems="center" item sm={12} md={6} className="hr-solutions">
                            {list.map((x, i) => {
                                const Result = (screen) => {
                                    return(
                                        <Grid container spacing={3}>
                                            <Grid item style={{flex: 0}} container alignItems="center">
                                                <Box position="relative" zIndex="2" className="p30" borderRadius={200} bgcolor="var(--secondary)">
                                                    <Box height={35} width={35} overflow="hidden" color="var(--white)">
                                                        {x.icon}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                                <Grid item container style={{flex: 1}}>
                                                <Typography variant="h5" className="f_w-500">{x.title}</Typography>
                                                <Typography variant="body1">{x.desc}</Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                return(
                                    <>
                                        <Hidden smDown>
                                            <Box className={`pr100${i !== 2 ? " mb50" : ""}`} color="var(--thirdColor)">
                                                <Result screen="desktop" />
                                            </Box>
                                        </Hidden>
                                        <Hidden mdUp>
                                            <Box className={`pb30${i !== 2 ? " mb50" : ""}`} color="var(--thirdColor)">
                                                <Result screen="mobile" />
                                            </Box>
                                        </Hidden>
                                    </>
                                )
                            })}
                        </Grid>
                        <Grid container item sm={12} md={6} spacing={2}>
                            <Grid item xs={12}>
                                <Box width="100%">
                                    <img src="/img/hr1.jpeg" alt="hr solutions" style={{width: "100%"}} />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box width="100%">
                                    <img src="/img/hr2.jpeg" alt="hr solutions" style={{width: "100%"}} />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box width="100%">
                                    <img src="/img/hr3.jpeg" alt="hr solutions" style={{width: "100%"}} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}