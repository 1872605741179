import React, {Component} from "react";
import { Box, Container, Typography, GridList, GridListTile, Hidden } from "@material-ui/core";
import { covidImg, csrHealthCheckup } from "../data/csr";

class CSR extends Component{
    render() {
        return(
            <Box width="100%" bgcolor="var(--white)">
                <Container maxWidth="lg" className="pt100">
                    <Box className="pt100">
                        <Typography variant="h5" className="f_w-500 mb20">Thermameter Distribution, To Fight With Covid-19</Typography>
                        <Hidden smDown>
                            <GridList spacing={10} cellHeight={500} cols={3}>
                                {covidImg.map(m => (
                                    <GridListTile key={m.alt} cols={1}>
                                        <img src={m.src} alt={m.alt} />
                                    </GridListTile>
                                ))}
                            </GridList>
                        </Hidden>
                        <Hidden mdUp>
                            <GridList spacing={10} cellHeight="500" cols={1}>
                                {covidImg.map(m => (
                                    <GridListTile key={m.alt} cols={1}>
                                        <img src={m.src} alt={m.alt} />
                                    </GridListTile>
                                ))}
                            </GridList>
                        </Hidden>
                        <Typography variant="h5" className="f_w-500 mt100 mb20">Free Health Checkup</Typography>
                        <Hidden smDown>
                            <GridList spacing={10} cellHeight={400} cols={2}>
                                {csrHealthCheckup.map(m => (
                                    <GridListTile key={m.alt} cols={1}>
                                        <img src={m.src} alt={m.alt} />
                                    </GridListTile>
                                ))}
                            </GridList>
                        </Hidden>
                        <Hidden mdUp>
                            <GridList spacing={10} cellHeight="500" cols={1}>
                                {csrHealthCheckup.map(m => (
                                    <GridListTile key={m.alt} cols={1}>
                                        <img src={m.src} alt={m.alt} />
                                    </GridListTile>
                                ))}
                            </GridList>
                        </Hidden>
                    </Box>
                </Container>
            </Box>
        );
    }
}

export default CSR;